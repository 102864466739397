<template>
  <a-card title="新客户统计" size="small">
    <template slot="extra">
      <a-range-picker v-model="dateRange" size="small" :allowClear="false" style="margin: -2px 0" @change="queryData" />
    </template>
    <div id="ClientStatsChart" style="height: 260px"></div>
  </a-card>
</template>

<script>
import { dashboardClientStats } from "@/apis/stats";
import { each, groupBy } from "@antv/util";
import { Column } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      dateRange: [moment().subtract(6, "days"), moment()],
      chartPlot: null,
    };
  },
  methods: {
    initData() {
      this.chartPlot = new Column("ClientStatsChart", {
        data: [],
        isStack: true,
        xField: "date",
        yField: "count",
        appendPadding: [12, 0, 0, 0],
        seriesField: "intention",
        label: {
          position: "middle",
          content: (item) => (item.count === 0 ? "" : item.count),
          layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }],
        },
      });
      this.chartPlot.render();
      this.queryData();
    },
    queryData() {
      const startDate = this.dateRange[0].clone();
      const endDate = this.dateRange[1].clone().add(1, "days");

      dashboardClientStats({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      }).then((data) => {
        const annotations = [];
        each(groupBy(data, "date"), (values, k) => {
          const value = values.reduce((a, b) => a + b.count, 0);
          annotations.push({
            type: "text",
            position: [k, value],
            content: value === 0 ? "" : value,
            style: { textAlign: "center", fontSize: 14, fill: "rgba(0,0,0,0.85)" },
            offsetY: -12,
          });
        });

        this.chartPlot.changeData(data);
        this.chartPlot.update({ annotations });
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
